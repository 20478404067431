




















import { Component, Prop, Vue } from "vue-property-decorator";

interface Reply {
  [index: string]: string;
}

@Component({
  name: "ReplyCard",
})
export default class ReplyCard extends Vue {
  @Prop() data!: Array<Reply>;
}
