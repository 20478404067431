import { render, staticRenderFns } from "./ReplyCard.vue?vue&type=template&id=77cb4031&scoped=true&"
import script from "./ReplyCard.vue?vue&type=script&lang=ts&"
export * from "./ReplyCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77cb4031",
  null
  
)

export default component.exports