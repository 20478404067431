<template>
  <component :is="layout">
    <prompt :active="modalOpen" @close="close" :title="'View reply'">
      <ReplyCard :data="data" slot="content" />
    </prompt>
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-header">
            <h5>Questionnaire submissions</h5>
          </div>
          <div class="card-body">
            <div v-if="fieldGroups.length">
              <div
                v-for="group in fieldGroups"
                :key="group.id"
                class="d-flex mb-3 align-items-center"
              >
                <div class="grow">
                  Submission from: <strong>{{group.customerName}}</strong><br/>
                </div>
                <button
                  class="btn btn-primary float-right"
                  @click="showReply(group.data)"
                >
                  View
                </button>
              </div>
            </div>
            <div v-else>
              <p>
                There are no replies to this questionnaire.
              </p>
            </div>
            <pagination
              :data="fieldGroups"
              :limit="3"
              :show-disabled="true"
              align="right"
              @pagination-change-page="setPage"
            >
              <span slot="prev-nav" class="text-capitalize">
                <i class="fas fa-chevron-left mr-2" /> Prev
              </span>
              <span slot="next-nav" class="text-capitalize">
                Next <i class="fas fa-chevron-right ml-2" />
              </span>
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import Prompt from "@/components/UI/Prompt.vue";
import ReplyCard from "../../components/UI/ReplyCard.vue";
export default {
  name: "FieldGroupConfigList",
  components: {
    Default,
    ConfigurationNav,
    Prompt,
    ReplyCard,
  },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get(`fieldGroup?config=${_id}`).then((response) => {
      next((vm) => {
        vm.fieldGroups = response.data.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      fieldGroups: [],
      modalOpen: false,
      page: 1,
      data: {},
    };
  },
  methods: {
    close() {
      this.modalOpen = false;
    },
    getFirstAnswer(data) {
      const keys = Object.keys(data);
      return data[keys[0]];
    },
    showReply(data) {
      this.modalOpen = true;
      this.data = data;
    },
    setPage(page) {
      this.page = page;
      this.getOrders(page, this.activeSegment);
    },
    getReplies() {
      const _id = this.$route.params.id;
      jsonAPI
        .get(`questionnaire/reply?config=${_id}&page=${this.page}`)
        .then((response) => {
          this.fieldGroups = response.data.data;
        });
    },
  },
};
</script>
<style scoped>
.grow {
  flex-grow: 1;
}
</style>
